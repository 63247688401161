import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/app/error.module.scss");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/components/molecules/styles/img.module.scss");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/icons/notfound.png");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/placeholder/Beauty.png");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/placeholder/Food.png");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/placeholder/Healthcare.png");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/placeholder/medicine.png");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/placeholder/Pet & vet.png");
import(/* webpackMode: "eager" */ "/mnt/runnerFiles/mweb/t3-review-system/src/services/utils/placeholder/Undefine_image.png")